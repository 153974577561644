<template>
  <!-- eslint-disable -->
  <v-container id="login" class="px-0" fluid tag="section">
    <v-row class="login-form-row mx-0" align="center" justify="center">
      <v-col class="d-flex align-center justify-center" cols="12" sm="10" md="8" lg="6">
        <v-card class="login-form-card px-3 py-5" ref="form">
          <v-col>
            <h1 class="display-1 text-left pb-3 mb-3">Administrator Login</h1>
            <v-form ref="form" @submit.prevent="loginFn">
              <v-row align="center" justify="center">
                <v-col cols="12" sm="6" class="py-0">
                  <v-text-field
                    ref="name"
                    v-model="email"
                    label="Email"
                    outlined
                    :error-messages="errorEmail"
                    :rules="[() => !!checkEmailFn(email) || 'This email is invalid ']"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row align="center" justify="center">
                <v-col cols="12" sm="6" class="py-0">
                  <v-text-field
                    :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show ? 'text' : 'password'"
                    ref="name"
                    v-model="password"
                    label="Password"
                    outlined
                    @click:append="show = !show"
                    :error-messages="errorPassword"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row align="center" justify="center" class="mx-auto mb-3 mt-n1 error-row">
                <span class="caption text-center error--text">{{mainError}}</span>
              </v-row>
              <v-row align="center" justify="center">
                <v-col cols="6" class="d-flex flex-column align-center justify-space-between py-0">
                  <v-btn
                    type="submit"
                    class="px-12 mb-3 mx-auto"
                    color="secondary"
                    tile
                    depressed
                    bottom
                    :loading="isApiLoad"
                  >Login</v-btn>
                  <!-- :loading="isApiLoad" -->
                  <!-- <v-btn class="text-none" color="primary" text to="/forget-password">
                    <u>Forgot my password</u>
                  </v-btn>-->
                </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/* eslint-disable */

import CheckEmail from "../../components/util/CheckEmail";
import LoginApicalls from "../../services/login.service";
export default {
  name: "LogIn",
  data() {
    return {
      isApiLoad: false,
      email: null,
      password: null,
      errorEmail: null,
      mainError: null,
      errorPassword: null,
      show: false, //password show and hide
      staticErrorMessages: {
        email: {
          isEmpty: "Email Address cannot be empty"
        },
        password: {
          isEmpty: "Password cannot be empty"
        }
      }
    };
  },
  watch: {
    email() {
      this.errorEmail = null;
    },
    password() {
      this.errorPassword = null;
    }
  },
  methods: {
    checkEmailFn(emailAddress) {
      return CheckEmail(emailAddress);
    },
    loginFn() {
      if (this.email && this.password) {
        let payload = {
          Email: this.email,
          Password: this.password
        };
        this.isApiLoad = true;
        LoginApicalls.logIn(payload).then(res => {
          if (res.data.message == "success") {
            console.log(res.data.content);
            localStorage.setItem("admin", JSON.stringify(res.data.content));
            let payload2 = {
              snackBarText: " Admin logged in successfully",
              snackBarColor: "success"
            };
            this.$store.dispatch("notify/setSnackbarMessage", payload2);
            // LoginApicalls.logIn().then(res => {
            //   // this.items = res.data.content.data;
            //   console.log(res.data.content);
            // });
            location.replace("/");
          } else {
            this.isApiLoad = false;
            this.mainError = res.data.details.error;
            // this.email = "";
            this.password = "";
            setTimeout(() => {
              this.mainError = null;
            }, 3000);
            //   let payload2 = {
            //     snackBarText: "Invalid email or password",
            //     snackBarColor: "error"
            //   };
            //   this.$store.dispatch("notify/setSnackbarMessage", payload2);
          }
        });
      } else {
        if (!this.email) {
          this.errorEmail = this.staticErrorMessages.email.isEmpty;
        }
        if (!this.password) {
          this.errorPassword = this.staticErrorMessages.password.isEmpty;
        }
      }
    }
  }
};
</script>
<style>
#login {
  background-image: linear-gradient(
      to left,
      rgba(53, 167, 255, 0.3),
      rgba(53, 167, 255, 0.3)
    ),
    url("https://source.unsplash.com/w1g2o4J_4Dg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh;
}
.login-form-card {
  position: relative;
  width: 600px;
}
.login-form-row {
  /* position: absolute; */
  top: 0;
  min-width: 100vw;
  /* the 64px is to offset the top app bar */
  height: calc(100vh - 64px);
  min-height: 300px;
}
.login-image-credits {
  position: absolute;
  bottom: 5px;
  right: 5px;
  color: white;
}
.login-image-credits a {
  color: white;
}
.login-image-credits a:hover {
  color: gray;
}
/* Medium devices (large tablet to laptop, less than 960px) */
@media (max-width: 960px) {
  .login-form-card {
    width: 80vw;
    min-width: 200px;
  }
  /* the 56px is to offset the top app bar on smaller devices */
  .login-cover-image {
    height: calc(100vh - 56px);
  }
  /* the 56px is to offset the top app bar on smaller devices */
  .login-form-row {
    height: calc(100vh - 56px);
  }
}
</style>